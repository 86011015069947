var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-img",
            {
              attrs: {
                height: "709",
                position: "relative",
                contain: "",
                src: require("@/assets/ielts/Rectangle1.svg"),
              },
            },
            [
              _c(
                "v-row",
                {
                  staticStyle: { height: "100%" },
                  attrs: {
                    "no-gutters": "",
                    justify: "center",
                    align: "center",
                  },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex",
                      staticStyle: {
                        "flex-direction": "column",
                        "padding-left": "136px",
                      },
                      attrs: { cols: "5" },
                    },
                    [
                      _c("h1", { staticClass: "heading1" }, [
                        _vm._v("Mastermind IELTS Training Program"),
                      ]),
                      _c("p", { staticClass: "pt-8" }, [
                        _vm._v(
                          " To put your skills to the test, we offer comprehensive mock tests that closely mirror the actual IELTS exam format. These tests encompass both written and speaking components, providing invaluable practice and allowing you to familiarize yourself with the exam conditions. Our detailed feedback and performance analysis will guide you in identifying areas for improvement, empowering you to refine your strategies and enhance your performance. "
                        ),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              staticStyle: {
                                background: "#0099dc",
                                "margin-top": "33px",
                                padding: "16px",
                              },
                              attrs: { height: "56", width: "150" },
                            },
                            [
                              _vm._v(" Enroll now "),
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v("mdi-arrow-right"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-col", { staticStyle: { "margin-left": "180px" } }, [
                    _c("iframe", {
                      staticStyle: {
                        "border-radius": "20px",
                        border: "0px solid",
                      },
                      attrs: {
                        allow:
                          "accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture",
                        allowfullscreen: "",
                        height: "477",
                        width: "754",
                        src: "https://www.daraz.com/",
                      },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "space-between" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center",
              staticStyle: { "flex-direction": "column" },
              attrs: { cols: "9" },
            },
            [
              _c(
                "v-row",
                {
                  staticStyle: { "margin-top": "60px" },
                  attrs: {
                    "no-gutters": "",
                    justify: "center",
                    align: "center",
                  },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex",
                      staticStyle: {
                        "flex-direction": "column",
                        "padding-left": "132px",
                      },
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "xl:text-2xl lg:text-2xl md:text-2xl sm:text-2xl pl-2 pb-6",
                          staticStyle: { color: "#2b4570" },
                        },
                        [_vm._v(" Course highlights ")]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "d-flex justify-space-around pr-16",
                          attrs: { "no-gutters": "" },
                        },
                        _vm._l(_vm.advertisedContents, function (adds, i) {
                          return _c(
                            "v-card",
                            {
                              key: i,
                              staticClass: "rounded-xl pa-4",
                              attrs: {
                                flat: "",
                                height: "198",
                                width: "198",
                                color: adds.color,
                              },
                            },
                            [
                              _c("v-row", { staticClass: "ma-0" }, [
                                _c("img", { attrs: { src: adds.icon } }),
                              ]),
                              _c("v-row", { staticClass: "ma-0" }, [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "#2b4570",
                                      "font-family": "Roboto",
                                      "font-size": "20px",
                                      "font-weight": "700",
                                      "padding-top": "8px",
                                      "padding-bottom": "5px",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(adds.count) + " ")]
                                ),
                              ]),
                              _c("v-row", { staticClass: "ma-0" }, [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "#2b4570",
                                      "font-family": "Roboto",
                                      "font-size": "20px",
                                      "font-weight": "700",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(adds.text) + " ")]
                                ),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticStyle: {
                    "margin-top": "110px",
                    "margin-bottom": "50px",
                  },
                  attrs: { "no-gutters": "", justify: "start", align: "start" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { "padding-left": "132px" },
                      attrs: { cols: "8" },
                    },
                    [
                      _c(
                        "v-card",
                        { staticStyle: { color: "#000" }, attrs: { flat: "" } },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "xl:text-2xl lg:text-2xl md:text-2xl sm:text-2xl pl-2 pb-6",
                              staticStyle: { color: "#2b4570" },
                            },
                            [_vm._v(" Course Table Contents ")]
                          ),
                          _vm.screenWidth < 600
                            ? _c("v-select", {
                                attrs: {
                                  items: _vm.sections.map((section, i) => {
                                    return {
                                      text: `${section} (${section.contents.length})`,
                                      value: i,
                                    }
                                  }),
                                  dense: "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.selectedSection,
                                  callback: function ($$v) {
                                    _vm.selectedSection = $$v
                                  },
                                  expression: "selectedSection",
                                },
                              })
                            : _c(
                                "v-slide-group",
                                {
                                  staticClass: "pl-2",
                                  attrs: { mandatory: "", "show-arrows": "" },
                                  model: {
                                    value: _vm.selectedSection,
                                    callback: function ($$v) {
                                      _vm.selectedSection = $$v
                                    },
                                    expression: "selectedSection",
                                  },
                                },
                                _vm._l(_vm.sections, function (section, index) {
                                  return _c("v-slide-item", {
                                    key: index,
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ active, toggle }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mr-3 pa-2 d-flex align-center xl:text-sm lg:text-sm md:text-sm sm:text-xs",
                                                  class:
                                                    _vm.classes.slideItem.outer[
                                                      active
                                                    ],
                                                  attrs: {
                                                    depressed: "",
                                                    "input-value": active,
                                                  },
                                                  on: { click: toggle },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(section.title) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml-2 rounded",
                                                      class:
                                                        _vm.classes.slideItem
                                                          .inner[active],
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            section.contents
                                                              .length
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }),
                                1
                              ),
                          _c(
                            "v-expansion-panels",
                            {
                              staticClass: "pl-2",
                              attrs: { flat: "" },
                              model: {
                                value: _vm.expandedModule,
                                callback: function ($$v) {
                                  _vm.expandedModule = $$v
                                },
                                expression: "expandedModule",
                              },
                            },
                            _vm._l(_vm.selected, function (module_id, index) {
                              return _c(
                                "v-expansion-panel",
                                {
                                  key: index,
                                  attrs: { "active-class": "panel-active" },
                                },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass: "pl-0 pb-0",
                                      attrs: {
                                        "hide-actions": "",
                                        "disable-icon-rotate": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "panel-card",
                                          attrs: { flat: "", color: "#F4F4F4" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "py-1 d-flex align-center",
                                              class: _vm.classes.header,
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-grow-1 clickable",
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(index + 1) +
                                                        ".   " +
                                                        _vm._s(
                                                          _vm.moduleName(
                                                            module_id
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                { attrs: { icon: "" } },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "panel-arrow",
                                                      attrs: { size: "25" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          index !=
                                                            _vm.expandedModule
                                                            ? "mdi-plus"
                                                            : "mdi-close"
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c("public-module-contents", {
                                        staticStyle: { color: "#f4f4f4" },
                                        attrs: {
                                          contents:
                                            _vm.getModuleContents(module_id),
                                          module_id: module_id,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-col"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end" },
            [
              _c("v-card", {
                staticStyle: { "border-radius": "0" },
                attrs: {
                  flat: "",
                  height: "919",
                  width: "429",
                  color: "#FAFAFA",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }